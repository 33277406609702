@import "../../scss/variables";

@function makeLongShadow($length,$angle) {
	$val: 0px 0px transparent;
	@for $i from 1 through $length {
		$val: #{$val}, #{$i}px #{$i*$angle}px #aaa;
	}
	@return $val;
}

.Player {
  &__shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    transform: rotate(-360deg);
    background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0));
    z-index: 850;
  }

  &__wrpHeader {
    z-index: 900;
  }

  &__logo {
    height: 38px;
    width: 125px;
  }

  &__controls {
    position: fixed;
    top: 3px;
    width: 95%;
    z-index: 900;
    padding: 0 4vw;

    header { 
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      color: #ffffff;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .title {
        width: 100%;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
      }
  
      h1 {
        color: #fff;
        font-size: 1.5em;
        font-weight: bold;

        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        font-family: $main-font;
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }

      svg {
        color: #fff;
        opacity: 0.5;
        margin-left: auto;
        font-size: 4em;
        padding: 10px;
        cursor: pointer;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      transition: all 0.2s ease-out;
    }
  }
	&__wrp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		overflow: hidden;
		z-index: 800;
		background: black;
	}

  &__box {
		position: relative;
    // padding-top: 56.25%;
		top: 0; bottom: 0; left: 0; right: 0;
		width: 100%;
		// height: 100%;
		// display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		overflow: hidden;
		z-index: 800;
		background: black;
	}

	&__text {
		text-align: center;
		font-family: $main-font;
		font-size: 12vmin;
		animation: textStyle 5s infinite;
		outline: none;
		white-space: nowrap;
	}
}

@keyframes textStyle {
	0% {
		text-shadow: makeLongShadow(100,1);
		color: #f3f3f3;
		transform: scale(1.5, 1.5);
	}
	10% {
		text-shadow: makeLongShadow(100,1.5);
		color: #f3f3f3;
		transform: scale(1.5, 1.5);
	}
	15% {
		color:#f3f3f3;
	}
	20% {
		color: $red;
		text-shadow: none;
		transform: scale(1.1, 1.1);
	}
	75% {
		opacity: 1;
	}
	80% {
		opacity: 0;
		color: $red;
		transform: scale(0.85, 0.9);
	}
	100% {
		opacity: 0;
	}
}