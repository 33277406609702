@import '../../scss/mixin';
@import '../../scss/variables';

.Auth {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-left: 5px;

    @include mq(lg) {
      padding-left: 65px;
    }

    &__topBox {
      display: flex;
      flex-direction: column;
      z-index: 200;
    }

    &__topAboutBox {
      margin-top: 15px;
      z-index: 200;
      max-width: 400px;
    }

    &__topAbout {
      font-family: $main-font;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      z-index: 200;
      max-width: 400px;
    }

    &__bgLayer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(14, 14, 14, 0.6);
        z-index: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../assets/login.jpeg");
    }

    &__opacityLayer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(14, 14, 14, 0.6);

        @include mq(lg) {
          background-color: transparent;
        }
    }

    &__logo {
        display: block;
        margin-top: 15px;
        margin-bottom: 15px;
        z-index: 200;
        width: 128px;
        height: 38px;
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 100%;
        max-width: 90%;
        max-width: 390px;
        border-radius: 8px;
        background-color: transparent;
        margin-left: 5px;

        @include mq(lg) {
          margin-left: 65px;
          left: 0%;
          transform: translate(0%, -50%);
        }

        @include mq(md) {
            max-width: 500px;
        }

        &--title {
            font-size: 1.2rem;
            margin-bottom: 1em;
            font-family: $main-font;
            @include mq(xs) {
                font-size: 1.5rem;
            }
        }

        &--divider {
            margin: 1.5em;
            border-color: #777777;
        }

        &--toggleView {
            display: inherit;
            font-family: $main-font;
            font-size: 14px;
            line-height: 1.4;
            color: #747474;
            text-align: center;

            .toggler {
                display: inline-block;
                color: $red;
                font-weight: bold;
                font-size: 14px;
                line-height: 1.4;
                cursor: pointer;
                transition: all 0.2s ease-out;

                &:hover {
                    color: darken($red, 5);
                    transform: scale(0.95);
                }

                @include mq(md) {
                    font-size: 16px;
                }
            }

            @include mq(md) {
                font-size: 16px;
            }
        }

        &--disclaimer {
            display: inherit;
            font-family: $main-font;
            font-size: 14px;
            line-height: 1.4;
            font-weight: 600;
            color: $red;
            text-align: center;
            margin-top: 1.5em;
        }

        &--errors {
            display: inherit;
            margin-top: 1.5em;
            font-family: $main-font;
            font-size: 14px;
            line-height: 1.4;
            color: orange;
            text-align: center;

            @include mq(md) {
                font-size: 16px;
            }
        }
    }
}