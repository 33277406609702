@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import './scss/mixin';
@import './scss/variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $main-font;
  background-color: $black;
  color: $white;
}

@font-face {
  font-family: "Futura";
  src: local("Futura"), url(./assets/futura/futur.ttf) format("truetype");
}

@font-face {
  font-family: "LucianCAT";
  src: local("Lucian"),
    url(./assets/lucien-schoenschriftv-cat/LucianCAT.ttf) format("truetype");
}
