@import '../../scss/variables';
@import '../../scss/mixin';

.Skeleton__Banner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 90vh;
  padding: 0 4vw;
  padding-bottom: 10vh;
  background: $black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  @include mq(lg) {
    align-items: flex-start;
    justify-content: center;
    height: 80vh;
    padding-bottom: unset;
  }

  .Skeleton__inline {
    justify-content: center;

    @include mq(lg) {
      justify-content: flex-start;
    }
  }
}